export const textField = {
  '&:not(:last-child)': {
    marginBottom: '1.25rem',
  },
  '& > label': {
    display: 'block',
    marginBottom: '0.3125rem',
  },
  '& > div': {
    display: 'flex',
  },
};
