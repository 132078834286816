import {
  Box,
  OutlinedInput,
  OutlinedInputProps,
  MenuItem,
  ListSubheader,
  Select,
  SelectChangeEvent,
  FormHelperText,
  CircularProgress,
  MenuItemProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { textField } from 'theme/custom/text-field';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const PREFIX = 'TextField';

const classes = {
  textField: `${PREFIX}TextField`,
  menuPaper: `${PREFIX}MenuPaper`,
  label: `${PREFIX}Label`,
};

const StyledBox = styled(Box)({
  [`&.${classes.textField}`]: {
    ...textField,
  },
  [`& .${classes.menuPaper}`]: {
    maxHeight: 'calc(100% - 200px)',
  },
  [`& .${classes.label}`]: {
    fontSize: '.875rem',
    fontWeight: 400,
  },
});

export interface Option {
  value: MenuItemProps['value'];
  label: ReactNode;
}

export type Options =
  | Array<Option>
  | Array<
      | Option
      | {
          sectionName: string;
          options: Option[];
        }
    >;

interface Props extends OutlinedInputProps {
  readonly endNode?: ReactNode;
  readonly options?: Options;
  readonly defaultValue?: string;
  readonly name: string;
  readonly formatValue?: (val: string) => string;
  readonly showLabel?: boolean;
  readonly rules?: Omit<RegisterOptions<any, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  readonly handleSelect?: (value: string) => void;
  readonly isLoading?: boolean;
}

export function TextField({
  label,
  name,
  endNode,
  options,
  defaultValue = '',
  rules,
  showLabel = true,
  formatValue,
  handleSelect,
  isLoading = false,
  ...props
}: Props) {
  const { control } = useFormContext();
  const id = `${name ?? new Date().getTime()}-input`;
  const { t } = useTranslation();

  if (!control) {
    return null;
  }

  const optionsToUse = options?.reduce<Array<Option & { sectionName?: string }>>((prev, next) => {
    if ('value' in next) {
      return [...prev, next];
    }
    return [...prev, { sectionName: next.sectionName, value: '', label: '' }, ...next.options];
  }, []);

  if (options?.length) {
    return (
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field }) => (
          <StyledBox className={classes.textField}>
            {label && showLabel ? (
              <label className={classes.label} htmlFor={id}>
                {label}
              </label>
            ) : null}
            <Select
              id={id}
              fullWidth
              variant="outlined"
              {...props}
              {...field}
              onOpen={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
              onChange={(e: SelectChangeEvent<any>) => {
                const rawValue = String(e.target.value);
                const value = formatValue ? formatValue(rawValue) : rawValue;
                handleSelect && handleSelect(value);
                field.onChange(value);
              }}
              IconComponent={
                isLoading
                  ? () => (
                      <Box sx={{ transform: 'translateY(3px)' }}>
                        <CircularProgress sx={{ svg: { transform: 'scale(.4)' } }} />
                      </Box>
                    )
                  : undefined
              }
              disabled={isLoading || props.disabled}
            >
              {optionsToUse?.map(item => {
                if (item.sectionName) {
                  return <ListSubheader key={item.sectionName}>{item.sectionName}</ListSubheader>;
                }

                return (
                  <MenuItem key={`${item.value}-${item.label}`} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            {endNode}
          </StyledBox>
        )}
        rules={rules}
      />
    );
  }

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState: { error } }) => {
        let hasError = !!error?.message;
        let errorMsg = error?.message;

        if (error?.type === 'required' && !hasError) {
          hasError = true;
          errorMsg = t('field_required');
        }

        return (
          <Box className={classes.textField}>
            {label && showLabel ? (
              <label className={classes.label} htmlFor={id}>
                {label}
              </label>
            ) : null}
            <OutlinedInput
              id={id}
              fullWidth
              {...props}
              {...field}
              onChange={e => {
                props.onChange && props.onChange(e);
                field.onChange(formatValue ? formatValue(e.target.value) : e);
              }}
              error={hasError}
            />
            {hasError ? <FormHelperText error>{errorMsg}</FormHelperText> : null}
            {endNode}
          </Box>
        );
      }}
      rules={rules}
    />
  );
}
